<template>
  <header>
    <h1>Polestar 车主指南</h1>
    <nav class="navbar navbar-inverse">
    <div class="container-fluid">
      <div class="navbar-header">
        <button type="button" class="navbar-toggle" data-toggle="collapse" data-target="#myNavbar">
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </button>
      </div>
      <div class="collapse navbar-collapse" id="myNavbar">
        <ul class="nav navbar-nav">
          <li><router-link to="/">首页</router-link></li>
          <li><router-link to="/super-charging-station">超充站查询</router-link></li>
          <li><router-link to="/service-station">服务站查询</router-link></li>
          <li><router-link to="/policy-history">历史协议文本</router-link></li>
          <!-- <li :class="{ active: currentPage === 'car-knowledge' }"@click="currentPage = 'car-knowledge'"><a href="#">用车知识</a></li> -->
        </ul>
      </div>
    </div>
  </nav>
  </header>
</template>

<script>
export default {
  name: 'HeaderPage'
};
</script>

<style>
h1,h2 {
  color: #e0762d;
}
.navbar-inverse .navbar-nav>.active>a {
  color: #e0762d;
}
inverse .navbar-nav>.active>a:focus, .navbar-inverse .navbar-nav>.active>a:hover  {
  color: #e0762d;
}
</style>