import Vue from 'vue';
import App from './App.vue';
import router from './router'; // 引入路由配置
import 'jquery/dist/jquery.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import Meta from 'vue-meta';

Vue.use(Meta);

new Vue({
  router, // 在这里将路由挂载到 Vue 实例
  render: h => h(App)
}).$mount('#app');