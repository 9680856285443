<template>
  <footer>
    <div class="container text-center">
      <p>Polestar 车主指南 &copy; 2024. All Rights Reserved.</p>
    </div>
  </footer>
  </template>

<script>
export default {
  name: 'FooterPage'
};
</script>

<style>
footer {
     /* 可以根据需要设置页脚的样式 */
     background-color: #f0f0f0;
     text-align: center;
     padding: 10px 0;
     margin-top: auto; /* 关键代码，将页脚推到最底部 */
   }
</style>