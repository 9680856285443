<template>
  <div id="app">
    <Header />
    <router-view />
    <Footer />
    
  </div>
  
</template>

<script>
import Header from './components/HeaderPage.vue';
import Footer from './components/FooterPage.vue';

export default {
  name: 'App',
  components: {
    Header,
    Footer
  }
};
</script>

<style>
@font-face { src: url(./assets/fonts/regular.woff2) format('woff2'), url(./assets/fonts/regular.woff) format('woff'); font-family: Polestar Unica; font-weight: 400; font-style: normal; font-display: swap; }
#app {
  font-family: Polestar Unica;
}
#app h1,h2 {
  color: #e0762d!important;
}
#app .navbar-inverse .navbar-nav>.active>a {
  color: #e0762d;
}
#app inverse .navbar-nav>.active>a:focus, .navbar-inverse .navbar-nav>.active>a:hover  {
  color: #e0762d;
}
thead {
    color: #e0762d;
    vertical-align: center;
    text-align: center;
}
tr {
    vertical-align: middle;
}
.table td,.table th {
    vertical-align: middle;
}
/* 媒体查询，当屏幕宽度小于600px时应用 */  
@media (max-width: 600px) {  
    table {  
        font-size: 6px; /* 在小屏幕上减小字体大小 */  
    } 
    .table td{
        padding: .35rem;
        font-weight: 500;
    }
    .table th {
        padding: .4rem;
        font-size: 7px;
        font-weight: 500;
    }
}
</style>
