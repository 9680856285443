import Vue from 'vue';
import VueRouter from 'vue-router';
Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'HomePage',
        component: () => import('../views/HomePage.vue')
    },
    {
        path: '/super-charging-station',
        name: 'SuperChargingStation',
        component: () => import('../views/SuperChargingStation.vue')
    },
    {
        path: '/service-station',
        name: 'ServiceStation',
        component: () => import('../views/ServiceStation.vue')
    },
    {
        path: '/policy-history',
        name: 'PolicyHistory',
        component: () => import('../views/PolicyHistory.vue')
    },
    {
        path: '/api',
        meta: {
            ignoreRoute: true
        }
    }
];

const router = new VueRouter({
    mode: 'hash',
    routes
});

router.beforeEach((to, from, next) => {
    if(to.meta && to.meta.ignoreRoute) {
        next(false)
    } else {
        next()
    }
})

export default router;